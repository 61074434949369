import { Button, Card, CardContent, CardHeader, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React, { useState, useEffect } from "react";
import { QRCode } from 'react-qrcode-logo';
import toastr from "toastr";
import "toastr/build/toastr.css";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Alert, AlertTitle } from '@material-ui/lab';
import jwt_decode from "jwt-decode";
import Timer from "./timer";
import $ from "jquery";
const TronGrid = require('trongrid');
const TronWeb = require('tronweb');
var sourceAddress = "";
var currency = "";
let orgAmount = 0;
let transInfo = {};
var curScriptId = "Waiting For Payment";
var ackId = '';
var successUrl = "";
var failureUrl = "";
var transactionCharge = 0;
var token = "b9dcc9f060cf4c35975f120c6fa51292";
require("dotenv").config();

function Dashboard() {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenInfo = urlParams.get('token');
    const dataInfo = urlParams.get('data');
    let seconds = 0;
    try {
        transInfo = jwt_decode(tokenInfo);
        let curAddress = jwt_decode(dataInfo);
        sourceAddress = curAddress.address;
        successUrl = transInfo.successUrl;
        failureUrl = transInfo.failureUrl;
        currency = transInfo.type;
        transactionCharge = curAddress.transactionCharge;
        orgAmount = currency.toLowerCase() === 'trx' ? parseFloat(curAddress.coins).toFixed(6) : curAddress.coins;
        ackId = curAddress.ackId;
        const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
        const endTime = transInfo.exp; // use UNIX timestamp in seconds
        seconds = endTime - stratTime;
    }
    catch (e) {
        console.log(e);
    }
    const [transactionId, setTransactionId] = useState("Waiting For Payment");
    const [status, setStatus] = useState("Waiting For Payment");
    const [amount, setAmount] = useState(orgAmount);
    const [timeLeft, setTimeLeft] = useState(seconds);
    const [confirmations, setConfirmations] = useState([]);
    const [curScriptId, setScriptId] = useState("Waiting For Payment");

    function pinger(ws) {
        var timer = setInterval(function () {
            if (ws.readyState == 1) {
                ws.send(JSON.stringify({ event: "ping" }));
            }
        }, 5000);
        return { stop: function () { clearInterval(timer); } };
    }



    useEffect(() => {
        if (orgAmount !== 0 && currency.toLowerCase() === "btc") {
            var ws = new WebSocket("wss://socket.blockcypher.com/v1/btc/main?token=" + token);
            var ping = pinger(ws);

            ws.onmessage = function (event) {
                let curData = JSON.parse(event.data);
                log(event.data);
                if (curData.confirmations <= 0) {
                    debugger;
                    setTimeLeft(120);
                    // setTransactionId(curData.hash);
                    let scriptId = curData.outputs.filter((x) => x.addresses.indexOf(sourceAddress) > -1);
                    if (scriptId.length > 0) {
                        setAmount(scriptId[0].value);
                        setScriptId(scriptId[0].script);
                        setTransactionId(curData.hash);
                        toastr.success("Transaction Initiated Waiting for the Block Chain to Confirm");
                        setStatus("Transaction Initiated Waiting for the Block Chain to Confirm");
                        fetch(process.env.REACT_APP_API_URL + ackId + '?hash=' + curData.hash, {
                            method: 'PUT',
                            headers: {
                                'Authorization': 'Bearer ' + tokenInfo
                            }
                        }).then((x) => {
                            ping.stop();
                            ws.close();
                            document.location.href = process.env.REACT_APP_RETURN_URL + "?payment=success";
                        }).catch((err) => {
                            toastr.error("We received your payment.but we are unabe process request now", "Update Status");
                            console.log(err);
                            setTimeout(() => {
                                document.location.href = process.env.REACT_APP_RETURN_URL + "?payment=failed";
                            }, 5000);
                        });
                    }
                }
                // else if (curData.confirmations > 0) {
                //     debugger;
                //     toastr.success("Transaction Confirmed By Block Chain");
                //     setStatus("Transaction Confirmed By Block Chain");
                //     ping.stop();
                //     ws.close();
                //     let scriptId = curData.outputs.filter((x) => x.addresses.indexOf(sourceAddress) > -1 && x.script === curScriptId);
                //     if (scriptId.length > 0) {
                //         let curConfirmations = confirmations;
                //         curConfirmations.push(scriptId[0].value);
                //         toastr.success("Confirmed " + scriptId[0].value + " sathosis");
                //         log("Confirmed " + scriptId[0].value + " sathosis");
                //         setConfirmations(curConfirmations);
                //         let total = curConfirmations.map(x => parseInt(x)).reduce((a, b) => a + b, 0);
                //         if (total === amount) {
                //             debugger;
                //             setTransactionId(curData.hash);
                //             fetch(process.env.REACT_APP_API + ackId + '?hash=' + curData.hash, {
                //                 method: 'PUT',
                //                 headers: {
                //                     'Authorization': 'Bearer ' + tokenInfo
                //                 }
                //             });
                //             ping.stop();
                //             ws.close();
                //         }
                //     }
                // }
            }

            ws.onopen = function (event) {
                ws.send(JSON.stringify({ event: "tx-confirmation", address: sourceAddress }));
            }

        }
        else if (orgAmount !== 0 && currency.toLowerCase() === "trx") {
            log("Transaction linked with block chain. Please make payment soon");
            const tronWeb = new TronWeb({
                fullHost: 'https://api.trongrid.io'
            });
            log("We are waiting for your payment");
            const tronGrid = new TronGrid(tronWeb);
            tronGrid.setExperimental("3b913ed3-d308-437d-b940-83f1e4958d02");

            let counter = 1;


            let cnt = setInterval(async () => {
                counter++;
                if (counter === 28) {
                    clearInterval(cnt);
                }
                await getTransactions();
                log("We are waiting for your payment");
            }, 20000)

            async function getTransactions() {
                const address = sourceAddress;

                const options = {
                    onlyTo: true,
                    limit: 10,
                    orderBy: 'timestamp,asc',
                    minBlockTimestamp: Date.now() - 60000 // from a minute ago to go on
                };

                // promise
                tronGrid.account.getTransactions(address, options).then(transactions => {
                    transactions.data.forEach((tran) => {
                        tran.raw_data.contract.forEach((ct) => {
                            if ((ct.parameter.value.amount / 1000000).toFixed(6) === parseFloat(orgAmount).toFixed(6)) {
                                clearInterval(cnt);
                                log("We got some transaction event. We are verifying.");
                                setAmount(parseFloat(orgAmount).toFixed(6));
                                curScriptId = tran.txID;
                                setTransactionId(tran.txID);

                                fetch(process.env.REACT_APP_API_URL + ackId + '?hash=' + tran.txID, {
                                    method: 'PUT',
                                    headers: {
                                        'Authorization': 'Bearer ' + tokenInfo
                                    }
                                }).then((x) => {
                                    ping.stop();
                                    ws.close();
                                    document.location.href = process.env.REACT_APP_RETURN_URL + "?payment=success";
                                }).catch((err) => {
                                    toastr.error("We received your payment.but we are unabe process request now", "Update Status");
                                    console.log(err);
                                    setTimeout(() => {
                                        document.location.href = process.env.REACT_APP_RETURN_URL + "?payment=failed";
                                    }, 5000);
                                });

                                toastr.success("Transaction Initiated Waiting for the Block Chain to Confirm");
                                setStatus("Transaction Initiated Waiting for the Block Chain to Confirm");
                                return;
                            }
                        })
                    })
                }).catch(err => console.error(err));
            }


        }
    }, []);

    if (orgAmount === 0) {
        return <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            You are Not Authorised to View this Page — <strong>check it out!</strong>
        </Alert>;
    }

    if (transactionId === "Waiting For Payment" && timeLeft <= 0) {
        return <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Session Expired — <strong>check it out!</strong>
        </Alert>;
    }

    if (transactionId !== "Waiting For Payment") {
        if (currency.toLowerCase() === "btc") {
            return <>
                <Alert severity="success">
                    <AlertTitle>Payment Success</AlertTitle>
                    Your Transaction is Confirmed with transaction hash&nbsp;&nbsp;&nbsp;
                    <a style={{ color: "white", wordBreak: "break-word" }}
                        href={"https://www.blockchain.com/btc/tx/" + transactionId}>{transactionId}</a>
                    <br /><strong>For more information&nbsp;&nbsp;&nbsp;
                        <a style={{ color: "white" }}
                            href={"https://www.blockchain.com/btc/tx/" + transactionId}>Click Here</a>
                    </strong>
                </Alert>
                <Alert severity="error">
                    <strong>Please dont click back or close the browser</strong>
                </Alert>
            </>;
        }
        else {
            return <Alert severity="success">
                <AlertTitle>Payment Success</AlertTitle>
                Your Transaction is Confirmed with transaction hash&nbsp;&nbsp;&nbsp;
                <a style={{ color: "white", wordBreak: "break-word" }}
                    href={"https://tronscan.org/#/transaction/" + transactionId}>{transactionId}</a>
                <br /><strong>For more information&nbsp;&nbsp;&nbsp;
                    <a style={{ color: "white" }}
                        href={"https://tronscan.org/#/transaction/" + transactionId}>Click Here</a>
                </strong>
            </Alert>;
        }
    }


    function log(msg) {
        console.log(msg);
        var node = document.createElement("LI");
        if (node) {             // Create a <li> node
            var textnode = document.createTextNode(msg);
            if (node && node.appendChild) {    // Create a text node
                node.appendChild(textnode);
            }
            if (document.getElementById("log")) {
                document.getElementById("log").appendChild(node);
            }
        }
    }

    // function pinger(ws) {
    //     var timer = setInterval(function () {
    //         if (ws.readyState == 1) {
    //             ws.send(JSON.stringify({ op: "ping" }));
    //         }
    //     }, 5000);
    //     return { stop: function () { clearInterval(timer); } };
    // }

    function copyText() {
        var copyText = document.getElementById("myInput");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");
        toastr.success("Link Copied to Clipboard");
    }

    return (
        <Card>
            <CardHeader title={"Coins : " + amount + " " + currency}
                subheader={"Transaction Status : " + transactionId} />
            <CardContent>

                <Grid container>
                    <Grid item md={6} sm={12} xs={12} style={{ "textAlign": "center" }}>
                        {
                            timeLeft > 300 ?
                                <>
                                    <QRCode value={sourceAddress} />
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        value={sourceAddress}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FileCopyIcon htmlColor="#b27919" onClick={() => copyText()} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{
                                            "aria-readonly": true,
                                            id: "myInput"
                                        }} />
                                    <Typography variant="h4" component="p">
                                        Or
                                    </Typography>
                                    <br />
                                    <div>
                                        {
                                            currency.toLowerCase() === "btc" ?
                                                <a
                                                    target="_blank"
                                                    className="btn"
                                                    href={`https://link.trustwallet.com/send?coin=0&address=${sourceAddress}&amount=${amount}`}>
                                                    Pay Using Trust Wallet
                                                </a>
                                                :
                                                <a
                                                    target="_blank"
                                                    className="btn"
                                                    href={`https://link.trustwallet.com/send?coin=195&address=${sourceAddress}&amount=${amount}`}>
                                                    Pay Using Trust Wallet
                                                </a>
                                        }
                                    </div>
                                </> :
                                <>
                                    <Alert severity="warning" style={{ textAlign: "left" }}>
                                        <AlertTitle>Transaction Status</AlertTitle>
                                        If Payment is already done by you, <strong>Please wait for some time for confirmations.</strong>
                                    </Alert>
                                    <br />
                                    <br />
                                    <Alert severity="error" style={{ textAlign: "left" }}>
                                        <AlertTitle>Skip Payment</AlertTitle>
                                        <a href={process.env.REACT_APP_RETURN_URL}>Click Here</a><strong> to leave the page without payment.</strong>
                                    </Alert>
                                </>
                        }

                        {
                            status === "Transaction Confirmed By Block Chain" ?
                                <Alert severity="success">
                                    <AlertTitle>Success</AlertTitle>
                                    Transaction Confirmed — <strong>check it out!</strong>
                                </Alert>
                                :
                                <div>
                                    <br />
                                    <br />
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AutorenewIcon />}
                                    >
                                        {status}
                                    </Button>
                                    <br />
                                    <br />
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<AutorenewIcon />}
                                    >
                                        {curScriptId}
                                    </Button>
                                </div>
                        }
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <ul className="details">
                            <li><label>Name: </label><b>{transInfo.name}</b></li>
                            <li><label>Deposit Amount:  </label><b>{(parseFloat(orgAmount) - parseFloat(transactionCharge)).toFixed(currency.toUpperCase() === "TRX" ? 6 : 8)} {currency.toUpperCase()}</b></li>
                            <li><label>Transaction Charge :  </label><b>{parseFloat(transactionCharge).toFixed(currency.toUpperCase() === "TRX" ? 6 : 8)} {currency.toUpperCase()}</b></li>
                            <li><label>Customer ID:  </label><b>{transInfo.cid}</b></li>
                            <li><label>Email:  </label><b>{transInfo.email}</b></li>
                            <li><label>Mobile No: </label><b>{transInfo.mobile}</b></li>
                            <li><label>Reference No: </label><b>{transInfo.txnid}</b></li>
                        </ul>
                        {
                            status === "Waiting For Payment" ?
                                <Timer duration={seconds} onComplete={(duration) => {
                                    setTimeLeft(duration);
                                }} /> : <span />
                        }

                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <ul id="log">
                        </ul>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default Dashboard;
